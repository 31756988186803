import React from "react"
import { useState, useMemo } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "./Loading"
import { addItem } from "../features/cart/cartSlice"
import { useDispatch } from "react-redux"
import StyledTable from "../components/StyledTable"
import { Checkbox, Stack, Typography, Box, Chip, IconButton, Snackbar, Alert } from "@mui/material"
import { getToggleAllRowsSelectedProps } from "../utils/utils"
import SelectedRowsDispatchButton from "./SelectedRowsDispatchButton"
import { hideIfRepeat } from "../utils/utils"
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined"

const allOrder = gql`
    query allOrder($pn: String, $status: String) {
        allOrder(filter: { pn: { eq: $pn }, status: { eq: $status } }) {
            nodes {
                id
                date
                num
                status
                total
                val
                items {
                    pos
                    line
                    pn
                    man
                    min_amount
                    amount
                    pcs
                    price
                    sum
                    accommodation
                    shippingDate
                }
            }
        }
    }
`

const gqlUpdateOrder = gql`
    mutation gqlUpdateOrder($id: String!, $operation: String!) {
        updateOrder(id: $id, operation: $operation) {
            num
        }
    }
`

const OrderHistory = ({ pn, status = "", showHeader = false }) => {
    const dispatch = useDispatch()

    const { keycloak, initialized } = useKeycloak()

    const { loading, error, data, refetch } = useQuery(allOrder, {
        fetchPolicy: "network-only", // Doesn't check cache before making a network request
        variables: { pn, status },
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })

    const [updateOrder, { data: data_upd, loading: loading_upd, error: error_upd, reset }] = useMutation(
        gqlUpdateOrder,
        {
            fetchPolicy: "no-cache", // Doesn't check cache before making a network request
            context: {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            },
        }
    )

    const [state, setState] = React.useState({ open: false, message: "", severity: "info" })
    const { open, message, severity } = state

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setState({ ...state, open: false })
    }

    React.useEffect(() => {
        if (data_upd) {
            setState(previousState => {
                return {
                    ...previousState,
                    open: true,
                    message: `Операция успешно выполнена ${data_upd.updateOrder.num}`,
                    severity: "success",
                }
            })
        }
        if (error_upd) {
            setState(previousState => {
                return {
                    ...previousState,
                    open: true,
                    message: `Ошибка при выполнении операции ${error_upd}`,
                    severity: "error",
                }
            })
        }
        reset()
        refetch()
    }, [data_upd, error_upd, reset, refetch])

    const handleOrderArchiving = React.useCallback(
        (e, id) => {
            e.preventDefault()

            updateOrder({ variables: { id, operation: "ПомещениеЗаказаВАрхив" } })
        },
        [updateOrder]
    )

    const cachedData = useMemo(() => {
        if (loading || error) return null

        const nodes = []

        data.allOrder.nodes.map(o =>
            o.items.map(item => {
                nodes.push({
                    id: o.id,
                    pos: item.pos,
                    line: item.line,
                    pn: item.pn,
                    man: item.man,
                    min_amount: item.min_amount,
                    amount: item.amount,
                    pcs: item.pcs,
                    price: item.price,
                    val: o.val,
                    sum: item.sum,
                    accommodation: item.accommodation,
                    shippingDate:
                        item.shippingDate !== "0001-01-01T00:00:00"
                            ? new Date(item.shippingDate).toLocaleDateString()
                            : "",
                    num: o.num,
                    status: o.status,
                    date: new Date(o.date).toLocaleDateString(),
                })
            })
        )

        return nodes
    }, [loading, error, data])

    const columns = useMemo(
        () => [
            {
                id: "select",
                disableSortBy: true,
                Header: ({ getToggleAllRowsSelectedProps }) => {
                    return <Checkbox {...getToggleAllRowsSelectedProps()} />
                },
                Cell: ({ row }) => {
                    return <Checkbox {...row.getToggleRowSelectedProps()} />
                },
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Заказ
                    </Typography>
                ),
                accessor: "num", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row, flatRows }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack spacing={1} sx={() => hideIfRepeat(flatRows.indexOf(row), value, flatRows, "num")}>
                                <Typography>{value}</Typography>
                                <Typography>{original.date}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <Chip label={original.status} color="primary" variant="outlined" size="small" />
                                    {original.status === "Подтвержден" && (
                                        <>
                                            <IconButton
                                                onClick={e => {
                                                    handleOrderArchiving(e, original.id)
                                                }}
                                            >
                                                <ArchiveOutlinedIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        )
                    } else
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Typography>{row.subRows[0].original.date}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <Chip
                                        label={row.subRows[0].original.status}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                    />
                                    {row.subRows[0].original.status === "Подтвержден" && (
                                        <>
                                            <IconButton
                                                onClick={e => {
                                                    handleOrderArchiving(e, row.subRows[0].original.id)
                                                }}
                                            >
                                                <ArchiveOutlinedIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        )
                },
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Typography>{original.pn}</Typography>
                                <Typography>{original.man}</Typography>
                            </Stack>
                        )
                    }
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Производитель
                    </Typography>
                ),
                accessor: "man", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Stack spacing={1}>
                            <Typography>{value}</Typography>
                        </Stack>
                    )
                },
                show: false,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Мин.
                    </Typography>
                ),
                disableSortBy: true,
                accessor: "min_amount",
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value: initialValue }) => {
                    const [value, setValue] = useState(initialValue)

                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <Typography>{value}</Typography>
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                aggregate: leafValues => leafValues[0],
                Aggregated: ({ value }) => {
                    return <Typography>{value}</Typography>
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                aggregate: "sum",
                Aggregated: ({ value }) => <Typography align="right">{value}</Typography>,
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Размещение
                    </Typography>
                ),
                accessor: "accommodation", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack spacing={1}>
                                <Typography noWrap>{value}</Typography>
                                <Typography noWrap>Дата отгрузки покупателю:</Typography>
                                <Typography>{original.shippingDate}</Typography>
                            </Stack>
                        )
                    }
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
        ],
        []
    )

    const addItemToInvoice = v =>
        dispatch(
            addItem({
                id: v.original.id,
                line: v.original.line,
                pos: v.original.pos,
                pn: v.original.pn,
                amount: v.original.amount,
                pcs: v.original.pcs,
                price: v.original.price,
                val: v.original.val,
                sum: v.original.sum,
                accommodation: v.original.accommodation,
                shippingDate: v.original.shippingDate,
                num: v.original.num,
                date: v.original.date,
            })
        )

    return (
        <>
            {showHeader && <Typography variant="h4">История заказов</Typography>}
            {loading && <Loading />}
            {error && <Typography>{`Error: ${error.message}`}</Typography>}
            {cachedData && (
                <Box>
                    <StyledTable
                        columns={columns}
                        data={cachedData}
                        onRowClick={(event, rowData) => {}}
                        hooks={hooks => {
                            hooks.getToggleAllRowsSelectedProps = [getToggleAllRowsSelectedProps]
                        }}
                        actions={[
                            <SelectedRowsDispatchButton
                                title="Добавить в счет"
                                msgWarning="Отсутствуют позиции для добавления в счет"
                                msgSuccess="Позиции были успешно добавлены в счет"
                                dispatchAction={addItemToInvoice}
                            />,
                        ]}
                        cellProps={() => ({ sx: { verticalAlign: "top" } })}
                        exportColumns={[
                            { accessor: "num" },
                            { accessor: "line" },
                            { accessor: "pn" },
                            { accessor: "man" },
                            { accessor: "min_amount" },
                            { accessor: "amount" },
                            { accessor: "pcs" },
                            { accessor: "price" },
                            { accessor: "val" },
                            { accessor: "sum" },
                            { accessor: "accommodation" },
                            { accessor: "shippingDate" },
                        ]}
                    />
                </Box>
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert severity={severity} onClose={handleCloseSnackBar} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default OrderHistory
