import { Box, Typography } from "@mui/material"
import React from "react"
import Catalog from "../components/Catalog"
import InvoiceHistory from "../components/InvoiceHistory"
import OrderHistory from "../components/OrderHistory"
import RequestHistory from "../components/RequestHistory"

import ShipmentHistory from "../components/ShipmentHistory"

const SearchResults = ({ location }) => {
    const { state = {} } = location
    const { pn } = state || {}
    if (pn === "") {
        return (
            <Box>
                <Typography>Ничего не найдено</Typography>
            </Box>
        )
    }
    return (
        <>
            <Catalog pn={pn} />
            <RequestHistory pn={pn} showHeader={true} />
            <OrderHistory pn={pn} showHeader={true} />
            <InvoiceHistory pn={pn} showHeader={true} />
            <ShipmentHistory pn={pn} showHeader={true} />
        </>
    )
}

export default SearchResults
