import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../components/Loading"
import { useDispatch, useSelector } from "react-redux"
import { clearInvoicesRegistry, addItem, removeItem, setAmount } from "../features/invoicesRegistry/invoiceSlice"

import StyledTable from "../components/StyledTable"
import { Stack, Typography, Box, Chip, Modal, Button } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { rowActionCell, hideIfRepeat } from "../utils/utils"
import EditInvoice from "./EditInvoice"

const allInvoiceQuery = gql`
    query allInvoiceQuery($pn: String, $status: String) {
        allInvoice(filter: { pn: { eq: $pn }, status: { eq: $status } }) {
            nodes {
                date
                invoiceNum
                status
                total
                val
                items {
                    pos
                    line
                    pn
                    amount
                    pcs
                    price
                    sum
                }
            }
        }
    }
`

const InvoiceHistory = ({ pn, status = "", showHeader = false }) => {
    const dispatch = useDispatch()
    const { invoiceItems, total, amount } = useSelector(store => store.invoicesRegistry)

    const { keycloak, initialized } = useKeycloak()

    const { loading, error, data, refetch } = useQuery(allInvoiceQuery, {
        fetchPolicy: "network-only", // Doesn't check cache before making a network request
        variables: { pn, status },
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })

    const cachedData = React.useMemo(() => {
        if (loading || error) return null

        const nodes = []

        data.allInvoice.nodes.map(o =>
            o.items.map(item => {
                nodes.push({
                    date: new Date(o.date).toLocaleDateString(),
                    invoiceNum: o.invoiceNum,
                    val: o.val,
                    status: o.status,
                    pos: item.pos,
                    line: item.line,
                    pn: item.pn,
                    amount: item.amount,
                    pcs: item.pcs,
                    price: item.price,

                    sum: item.sum,
                })
            })
        )

        return nodes
    }, [loading, error, data])

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Счет
                    </Typography>
                ),
                accessor: "invoiceNum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row, flatRows }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack
                                spacing={1}
                                sx={() => hideIfRepeat(flatRows.indexOf(row), value, flatRows, "invoiceNum")}
                            >
                                <Typography>{value}</Typography>
                                <Typography>{original.date}</Typography>
                                <Chip label={original.status} color="primary" variant="outlined" size="small" />
                            </Stack>
                        )
                    } else {
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Typography>{row.subRows[0].original.date}</Typography>
                                <Chip
                                    label={row.subRows[0].original.status}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                />
                            </Stack>
                        )
                    }
                },
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN
                    </Typography>
                ),
                accessor: "pn",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <Typography>{value}</Typography>
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                aggregate: leafValues => leafValues[0],
                Aggregated: ({ value }) => {
                    return <Typography>{value}</Typography>
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                aggregate: leafValues => {
                    return leafValues.reduce((sum, curr) => sum + curr, 0).toFixed(2)
                },
                Aggregated: ({ value }) => <Typography align="right">{value}</Typography>,
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
        ],
        [cachedData]
    )

    const [editState, setEditState] = React.useState({ open: false, invoiceNum: "", date: "", status: "" })
    const editItemFromInvoice = original => {
        dispatch(clearInvoicesRegistry())
        const rows = cachedData.filter(item => item.invoiceNum === original.invoiceNum)

        rows.map(item => {
            dispatch(addItem(item))
        })
        setEditState({ open: true, invoiceNum: original.invoiceNum, date: original.date, status: original.status })
    }

    const handleClose = (e, reason) => {
        setEditState({ open: false, invoiceNum: "", date: "", status: "" })

        if (reason !== "backdropClick") {
            refetch()
        }
    }

    return (
        <>
            {showHeader && <Typography variant="h4">История счетов</Typography>}
            {loading && <Loading />}
            {error && <p>{`Error: ${error.message}`}</p>}
            {cachedData && (
                <Box>
                    <StyledTable
                        columns={columns}
                        data={cachedData}
                        onRowClick={(event, rowData) => {}}
                        hooks={hooks => {
                            hooks.visibleColumns.push(columns => [
                                ...columns,
                                rowActionCell({
                                    IconComponent: EditOutlinedIcon,
                                    confirm: false,
                                    dispatchAction: editItemFromInvoice,
                                    accessor: "invoiceNum",
                                }),
                            ])
                        }}
                        actions={[]}
                        cellProps={() => ({ sx: { verticalAlign: "top" } })}
                    />
                </Box>
            )}

            <EditInvoice
                open={editState.open}
                onClose={handleClose}
                invoiceNum={editState.invoiceNum}
                date={editState.date}
                status={editState.status}
            />
        </>
    )
}

export default InvoiceHistory
