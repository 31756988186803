import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../components/Loading"
import StyledTable from "../components/StyledTable"
import { Stack, Typography, Box, Chip, Modal } from "@mui/material"
import { hideIfRepeat } from "../utils/utils"

const allShipmentQuery = gql`
    query allShipmentQuery($pn: String, $status: String) {
        allShipment(filter: { pn: { eq: $pn }, status: { eq: $status } }) {
            nodes {
                date
                shipmentNum
                status
                total
                val
                items {
                    pos
                    line
                    pn
                    amount
                    pcs
                    price
                    sum
                }
            }
        }
    }
`

const ShipmentHistory = ({ pn, status = "", showHeader = false }) => {
    const { keycloak, initialized } = useKeycloak()

    const { loading, error, data } = useQuery(allShipmentQuery, {
        fetchPolicy: "network-only", // Doesn't check cache before making a network request
        variables: { pn, status },
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })

    const cachedData = React.useMemo(() => {
        if (loading || error) return null

        const nodes = []
        data.allShipment.nodes.forEach(o =>
            o.items.forEach(item => {
                nodes.push({
                    date: new Date(o.date).toLocaleDateString(),
                    invoiceNum: o.shipmentNum,
                    val: o.val,
                    status: o.status,
                    pos: item.pos,
                    line: item.line,
                    pn: item.pn,
                    amount: item.amount,
                    pcs: item.pcs,
                    price: item.price,

                    sum: item.sum,
                })
            })
        )

        return nodes
    }, [loading, error, data])

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Отгрузка
                    </Typography>
                ),
                accessor: "invoiceNum", // accessor is the "key" in the data
                Cell: ({ row: { index, original }, value, row, flatRows }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack
                                spacing={1}
                                sx={() => hideIfRepeat(flatRows.indexOf(row), value, flatRows, "invoiceNum")}
                            >
                                <Typography>{value}</Typography>
                                <Typography>{original.date}</Typography>
                                <Chip label={original.status} color="primary" variant="outlined" size="small" />
                            </Stack>
                        )
                    } else {
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Typography>{row.subRows[0].original.date}</Typography>
                                <Chip
                                    label={row.subRows[0].original.status}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                />
                            </Stack>
                        )
                    }
                },
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN
                    </Typography>
                ),
                accessor: "pn",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <Typography>{value}</Typography>
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                aggregate: leafValues => leafValues[0],
                Aggregated: ({ value }) => {
                    return <Typography>{value}</Typography>
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                aggregate: leafValues => {
                    return leafValues.reduce((sum, curr) => sum + curr, 0).toFixed(2)
                },
                Aggregated: ({ value }) => <Typography align="right">{value}</Typography>,
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
        ],
        []
    )

    return (
        <>
            {showHeader && <Typography variant="h4">История отгрузок</Typography>}
            {loading && <Loading />}
            {error && <p>{`Error: ${error.message}`}</p>}
            {cachedData && (
                <Box>
                    <StyledTable
                        columns={columns}
                        data={cachedData}
                        onRowClick={(event, rowData) => {}}
                        hooks={hooks => {}}
                        actions={[]}
                        cellProps={() => ({ sx: { verticalAlign: "top" } })}
                    />
                </Box>
            )}
        </>
    )
}

export default ShipmentHistory
