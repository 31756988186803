import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../components/Loading"
import { Typography, Modal, Box, Chip, Stack, TextField, Button } from "@mui/material"
import StyledTable from "./StyledTable"
import { rowActionCell } from "../utils/utils"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { useDispatch, useSelector } from "react-redux"
import { clearInvoicesRegistry, addItem, removeItem, setAmount } from "../features/invoicesRegistry/invoiceSlice"

const gqlUpdateInvoice = gql`
    mutation gqlUpdateInvoice($positions: [invoiceUpdateType!]!) {
        updateInvoice(positions: $positions) {
            invoiceNum
        }
    }
`

const EditInvoice = ({ open, onClose, invoiceNum, date, status }) => {
    const dispatch = useDispatch()
    const { invoiceItems, total, amount } = useSelector(store => store.invoicesRegistry)

    const { keycloak, initialized } = useKeycloak()

    const cachedRows = React.useMemo(() => {
        return invoiceItems
    }, [invoiceItems])

    const removeItemFromInvoice = index => {
        dispatch(removeItem(index))
    }

    const [updateInvoice, { data: data_upd, loading: loading_upd, error: error_upd, reset }] = useMutation(
        gqlUpdateInvoice,
        {
            fetchPolicy: "no-cache", // Doesn't check cache before making a network request
            context: {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            },
        }
    )

    const handleInvoiceUpdates = () => {
        const positions = []
        cachedRows.map(item => {
            positions.push({ num: item.invoiceNum, pos: item.pos, pn: item.pn, amount: parseFloat(item.amount) })
        })

        updateInvoice({ variables: { positions } })
            .then(() => {
                reset()
                onClose()
            })
            .catch(err => {
                console.log(err)
            })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN
                    </Typography>
                ),
                accessor: "pn",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original, index }, value: initialValue }) => {
                    const [value, setValue] = React.useState(initialValue)

                    React.useEffect(() => {
                        setValue(initialValue)
                    }, [initialValue])

                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <TextField
                                id="amount-req"
                                variant="outlined"
                                size="small"
                                type="number"
                                value={value}
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    },
                                }}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}
                                onBlur={() => {
                                    dispatch(
                                        setAmount({
                                            index,
                                            amount: parseFloat(value),
                                        })
                                    )
                                }}
                            />
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
        ],
        []
    )

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.default",
                        boxShadow: 24,
                        width: "1200px",
                        p: 4,
                    }}
                >
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h5">
                            Счет № {invoiceNum} от {date}
                        </Typography>
                        <Chip label={status} color="primary" variant="outlined" />
                    </Stack>

                    <Box>
                        <StyledTable
                            columns={columns}
                            data={cachedRows}
                            onRowClick={(event, rowData) => {}}
                            hooks={hooks => {
                                hooks.visibleColumns.push(columns => [
                                    ...columns,
                                    rowActionCell({
                                        IconComponent: DeleteOutlineOutlinedIcon,
                                        title: "Удаление позиции из счета",
                                        question: "Вы подтверждаете удаление позиции из редактируемого счета?",
                                        dispatchAction: removeItemFromInvoice,
                                    }),
                                ])
                            }}
                            actions={[]}
                        />
                        <Stack direction="row">
                            <Button onClick={handleInvoiceUpdates}>Сохранить изменения</Button>
                        </Stack>
                    </Box>
                    {loading_upd && <Loading />}
                    {error_upd && <p>{`Error: ${error_upd.message}`}</p>}
                    {data_upd && (
                        <div>
                            <h3>Обработаны счета</h3>
                            {data_upd.updateInvoice.map(i => {
                                return <p key={i.invoiceNum}>{i.invoiceNum}</p>
                            })}
                        </div>
                    )}
                </Box>
            </Modal>
        </>
    )
}

export default EditInvoice
